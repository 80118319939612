import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    style={{
      marginBottom: `1.45rem`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 1200,
      }}
    >
      <h6 className="social_header">Follow me:</h6>
      <div className="social">
        <div><a href="https://open.spotify.com/artist/3cIkf9O2HRtpN4tVEj79xB?si=b7lnRSvZTvyExIaa6VEHOA" target="_blank" className="btn">SPOTIFY</a></div>
        <div><a href="https://frenchgeometry.bandcamp.com/follow_me" className="btn">BANDCAMP</a></div>
        <div><a href="https://www.twitter.com/frenchgeometry" target="_blank" className="btn">TWITTER</a></div>
        <div><a href="http://www.facebook.com/frenchgeometry" target="_blank" className="btn">FACEBOOK</a></div>
      </div>
      <h1>
          {siteTitle}
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
